import { $http } from "../api/axios.js";

function getSimpleError() {
    return { IsError: true }
}

export async function getOrderStatus(orderLink) {
    try {
        var result = await $http.post('receiver/getOrderInfo', { orderLink: orderLink });
    }
    catch (e) {
        console.log(e);
        return getSimpleError();
    }
    return result.data;
}


export async function changeOrderDeliveryDate(orderLink, deliveryDate) {
    try {
        var result = await $http.post('receiver/changeDeliveryDate', { orderLink: orderLink, deliveryDate: deliveryDate });
    }
    catch (e) {
        console.log(e);
        return getSimpleError();
    }
    return result.data;
}

export async function cancelOrder(orderLink) {
    try {
        var result = await $http.post('receiver/cancelOrder', { orderLink: orderLink });
    }
    catch (e) {
        console.log(e);
        return getSimpleError();
    }
    return result.data;
}

export async function sendDeliveryRate(orderLink, rateMark, rateComment) {
    try {
        var result = await $http.post('receiver/sendDeliveryRate', { orderLink: orderLink, rateMark: rateMark, rateComment: rateComment });
    }
    catch (e) {
        console.log(e);
        return getSimpleError();
    }
    return result.data;
}

export async function sendHelpQuestion(orderLink, questionComment) {
    try {
        var result = await $http.post('receiver/sendHelpQuestion', { orderLink: orderLink, questionComment });
    }
    catch (e) {
        console.log(e);
        return getSimpleError();
    }
    return result.data;
}

export async function getTipLink(orderLink) {
    try {
        var result = await $http.post('receiver/getTipLink', { orderLink: orderLink });
    }
    catch (e) {
        console.log(e);
        return getSimpleError();
    }
    return result.data;
}

export async function getOrderInfoByOrdId(orderId) {
    try {
        var result = await $http.post('receiver/getOrderInfoByOrdId', { orderId: orderId });
    }
    catch (e) {
        console.log(e);
        return getSimpleError();
    }
    return result.data;
}

export async function getPossibleDeliveryTimetable(orderLink) {
    try {
        var result = await $http.get('receiver/getPossibleDeliveryTimetable', { params: { orderLink: orderLink } });
    }
    catch (e) {
        console.log(e);
        return getSimpleError();
    }
    return result.data;
}