<template>
  <div id="app">
    <OrderStatus v-if="!showOrderCut"/>
    <OrderStatusCut v-if="showOrderCut"/>
  </div>
</template>

<script>
import OrderStatus from './components/OrderStatus.vue'
import OrderStatusCut from './components/OrderStatusCut.vue'

export default {
  name: 'App',
  components: {
    OrderStatus,
    OrderStatusCut
  },
  computed: {
    showOrderCut() {
      return this.$route.query.ord;
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
