<template>
  <div class="orderStatus">
    <div class="container container-header">
      <div style="display: flex; align-items: center; width: 100%">
        <div>
          <img
            width="88"
            src="/images/logo-stizh-logistic.png"
            alt="Логистическая компания Стриж"
            title="Логистическая компания Стриж"
          />
        </div>
        <div
          style="
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            margin-left: auto;
          "
        >
          <div>
            <a class="emails" href="https://www.strizh-logistic.ru"
              >https://www.strizh-logistic.ru</a
            >
          </div>
          <div>
            <a class="emails" href="mailto:info@strizh-logistic.ru"
              >info@strizh-logistic.ru</a
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="!this.isError && !this.isNotAccess && !this.isArchival" class="container">
      <div class="row">
        <div class="col" style="margin-right: 10px; margin-bottom: 1em">
          <div class="order-card">
            <div style="display: flex; min-width: 440px">
              <div class="order-card-number">Заказ №</div>
              <div class="order-card-number" style="color: rgb(255 0 0)">
                {{ orderData.OrderId }}
              </div>
              <div class="order-card-info">Статус заказа</div>
              <div class="order-card-status" @click="showStatuses">
                {{ lastStatus.StatusName }}
              </div>
              <div
                style="display: flex; align-items: center; margin-right: 5px"
                @click="showStatuses"
              >
                <i class="el-icon-warning-outline"></i>
              </div>
            </div>
            <div class="order-main-info" style="text-align: left">
              <div v-if="deliveryState == 1">
                <div class="form-field-key">Плановая дата доставки</div>
                <div>{{ deliveryDate }}</div>
              </div>
              <div v-if="deliveryState == 2">
                <div style="margin-top: 1em; margin-bottom: 1em">
                  Заказ передан курьеру
                </div>
              </div>
              <div v-if="deliveryState == 1 || deliveryState == 2">
                <div style="display: inline;">
                  Для получения заказа назовите курьеру код                  
                </div>
                <div class="identifier" style="display: inline;">{{ orderData.Identifier }}</div>
              </div>
              <div v-if="deliveryState == 4">Доставка отменена</div>
            </div>
            <div
              style="display: flex; flex-direction: column; margin-top: 15px"
            >
              <el-button
                class="btnIsActive"
                style="margin: 5px"
                @click="showDialogHelp(true)"
                >Помощь</el-button
              >
            </div>
          </div>
        </div>
        <div class="col">
          <div class="order-info">
            <div class="order-info-header">Информация о заказе</div>
            <div class="form-field">
              <div class="form-field-key">Отправитель</div>
              <div>{{ shopName }}</div>
            </div>
            <div class="form-field">
              <div class="form-field-key">Номер заказа отправителя</div>
              <div>{{ orderData.OrderIdImport }}</div>
            </div>
            <div class="form-field">
              <div class="form-field-key">Плановая дата доставки</div>
              <div>{{ deliveryDate }}</div>
            </div>
            <div class="form-field">
              <div class="form-field-key">Сумма к оплате</div>
              <div>{{ orderClientSum }}</div>
            </div>
            <div class="form-field">
              <div class="form-field-key">Способ оплаты</div>
              <div>{{ orderData.PayTypeName }}</div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="order-items">
            <div class="order-items-header">Состав заказа</div>
            <div class="order-items-count">
              <div>{{ orderData.Items.length + " товара" }}</div>
              <div class="order-items-count-circle">
                <div class="order-items-count-circle-img"></div>
              </div>
              <div>
                {{ orderData.OrderWeight > 0 ? orderData.OrderWeight : 0 }} кг
              </div>
            </div>
            <div
              class="order-items-it"
              v-for="item in orderData.Items"
              :key="item.Name"
            >
              <div style="text-align: left">{{ item.Name }}</div>
              <div style="white-space: nowrap">
                {{ item.Count + " x " + getStringPrice(item.Price) }}
              </div>
            </div>
            <div class="order-items-itog">
              <div class="order-items-itog-it">
                <div style="text-align: left">Товары</div>
                <div class="order-items-itog-separator"></div>
                <div class="order-items-itog-val">{{ itemsSum }}</div>
              </div>
              <div class="order-items-itog-deliv">
                <div style="text-align: left">Доставка</div>
                <div class="order-items-itog-separator"></div>
                <div class="order-items-itog-val">
                  {{ getStringPrice(orderData.DeliveryPrice) }}
                </div>
              </div>
              <div class="order-items-itog-itogo">
                <div style="text-align: left">Итого</div>
                <div class="order-items-itog-separator"></div>
                <div class="order-items-itog-val">{{ orderClientSum }}</div>
              </div>
            </div>
            <div class="order-items-comment">
              <div style="text-align: left">
                Ставя подпись на маршрутном листе или называя курьеру код для
                получения, получатель подтверждает что:
              </div>
              <div style="text-align: left">
                - заказ/товар получателем получен
              </div>
              <div style="text-align: left">
                - недостатки по внешнему виду и комплектации отсутствуют
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      custom-class="dialog"
      :center="true"
      :visible.sync="dialogStatuses"
    >
      <span
        slot="title"
        style="font-weight: 700; font-size: 16px; line-height: 24px"
      >
        Статус заказа
      </span>
      <span>
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in orderStatuses"
            :key="index"
            :timestamp="activity.timestamp"
            :icon="activity.icon"
            :type="activity.type"
          >
            {{
              activity.StatusName +
              (activity.ReasonName ? " (" + activity.ReasonName + ")" : "")
            }}
          </el-timeline-item>
        </el-timeline>
      </span>
    </el-dialog>
    <div v-if="this.isError">
      {{ "Не удалось загрузить данные" }}
    </div>
    <div v-if="this.isNotAccess">
      {{ "Данные недоступны" }}
    </div>
    <div v-if="this.isArchival">
      {{ "Извините, информация по заказу уже архиве" }}
    </div>
  </div>
</template>

<script>
import * as api from "../api/main.js";
import { dates } from "../scripts/dates.js";
export default {
  name: "OrderStatus",
  data: function () {
    let today = dates.methods.today();
    var checkChangeDelivDate = (date) => {
      let diffTime = date - today;
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return !(diffDays >= 1 && diffDays < 21);
    };

    return {
      dialogStatuses: false,
      orderStatuses: [],
      orderData: {
        OrderIdImport: null,
        OrderId: null,
        StatusName: null,
        StatusDate: null,
        Driver: null,
        CarPhone: null,
        IsOnDelivery: false,
      },
      deliveryDateNew: null,
      delivDateChangePickOpts: {
        disabledDate: checkChangeDelivDate,
        firstDayOfWeek: 1,
      },
      dialogHelp: false,
      questionComment: null,

      dialogRate: false,
      rateMark: null,
      rateComment: null,

      isError: false,
      isNotAccess: false,
      isArchival: false
    };
  },
  methods: {
    tipCourier() {
      let self = this;
      let id = this.$route.query.id;
      this.startLoading();
      api
        .getTipLink(id)
        .then((result) => {
          self.stopLoading();
          if (result.Data.TipUrl) {
            window.open(result.Data.TipUrl, "_blank");
          }
          //self.showDialogHelp(false);
        })
        .catch((e) => {
          self.isError = true;
          self.stopLoading();
        });
    },
    confirmSendQuestion() {
      let self = this;
      let id = this.$route.query.id;
      this.startLoading();
      api
        .sendHelpQuestion(id, self.questionComment)
        .then((result) => {
          self.stopLoading();
          self.showDialogHelp(false);
        })
        .catch((e) => {
          self.isError = true;
          self.stopLoading();
        });
    },
    confirmRate() {
      let self = this;
      let id = this.$route.query.id;
      this.startLoading();
      api
        .sendDeliveryRate(id, self.rateMark, self.rateComment)
        .then((result) => {
          self.stopLoading();
          self.showDialogRate(false);
        })
        .catch((e) => {
          self.isError = true;
          self.stopLoading();
        });
    },
    showDialogRate(isShow) {
      this.dialogRate = isShow;
    },
    showDialogHelp(isShow) {
      this.dialogHelp = isShow;
    },
    loadData() {
      let ordId = this.$route.query.ord;
      let self = this;
      if (ordId) {
        this.startLoading();
        api
          .getOrderInfoByOrdId(ordId)
          .then((result) => {
            self.stopLoading();
            if (result.Access) {
              self.orderStatuses = result.Data.OrderStatuses.map(function (
                item
              ) {
                item.timestamp = self.getDateTime(item.TimeStamp);
                return item;
              });
              if (self.orderStatuses.length > 0) {
                let orderStatus =
                  self.orderStatuses[self.orderStatuses.length - 1];
                orderStatus.icon = "el-icon-more";
                orderStatus.type = "primary";
              }
              self.orderData = result.Data.OrderData;
            } else if (result.IsArchival) {
              self.isArchival = true;
            } else {
              self.isNotAccess = true;
            }
          })
          .catch((e) => {
            self.isError = true;
            self.stopLoading();
          });
      } else {
        self.isError = true;
      }
    },
    showStatuses() {
      this.dialogStatuses = true;
    },
    getStringPrice(num) {
      return this.roundTwoDigits(num).toFixed(2) + " ₽";
    },
    roundTwoDigits(num) {
      return Math.round((num + Number.EPSILON) * 100) / 100;
    },
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
    getDateTime(dat) {
      if (dat) {
        return dates.methods.toStringDateTime(dat);
      }
      return "";
    },
    getDate(dat) {
      if (dat) {
        return dates.methods.toStringDate(dat);
      }
      return "";
    },
  },
  computed: {
    courierPhoneAttr() {
      return "tel:" + this.orderData.CarPhone;
    },
    lastStatus() {
      return this.orderStatuses[this.orderStatuses.length - 1];
    },
    changeDelivDateIsActive() {
      return this.orderData.CanChangeDeliveryDate;
    },
    refuseOrderIsActive() {
      return this.orderData.CanRefuseOrder;
    },
    deliveryState() {
      return this.orderData.DeliveryState;
    },
    itemsSum() {
      let itemsSum = this.orderData.Items.reduce(function (a, b) {
        return a + b.Price * b.Count;
      }, 0);
      return this.getStringPrice(itemsSum);
    },
    orderClientSum() {
      return this.getStringPrice(this.orderData.OrderClientSum);
    },
    isOnDelivery() {
      return this.orderData && this.orderData.IsOnDelivery;
    },
    deliveryDate() {
      let dat = this.orderData.DeliveryDate;
      if (dat) {
        try {
          let dd = new Date(dat);
          let month;
          switch (dd.getMonth()) {
            case 0:
              month = "января";
              break;
            case 1:
              month = "февраля";
              break;
            case 2:
              month = "марта";
              break;
            case 3:
              month = "апреля";
              break;
            case 4:
              month = "мая";
              break;
            case 5:
              month = "июня";
              break;
            case 6:
              month = "июля";
              break;
            case 7:
              month = "августа";
              break;
            case 8:
              month = "сентября";
              break;
            case 9:
              month = "октября";
              break;
            case 10:
              month = "ноября";
              break;
            case 11:
              month = "декабря";
              break;
          }
          return (
            dd.getDate() +
            " " +
            month +
            ", с " +
            this.orderData.DeliveryTimeFrom +
            " до " +
            this.orderData.DeliveryTimeTo
          );
        } catch (e) {
          console.log(e);
          return "";
        }
      }
      return "";
    },
    deliveryDateField() {
      if (this.orderData) {
        if (!this.orderData.IsOnDelivery) {
          return this.getDate(this.orderData.DeliveryDate);
        } else {
          return "Заказ на доставке";
        }
      }
      return "";
    },
    orderId() {
      if (this.orderData) {
        return this.orderData.OrderId;
      }
      return "";
    },
    info2() {
      let inf =
        "При возникновении вопросов, Вы можете написать на почту info@strizh-logistic.ru Обязательно укажите номер заказа ";
      if (this.orderData) {
        inf += this.orderData.OrderIdImport;
      }
      return inf;
    },
    shopName() {
      let shopName = "";
      if (this.orderData && this.orderData.MainShopName) {
        shopName = this.orderData.MainShopName;
      }
      if (this.orderData && this.orderData.SubShopName) {
        shopName += "/" + this.orderData.SubShopName;
      }
      return shopName;
    },
  },
  mounted: function () {
    this.loadData();
  },
};
</script>

<style>
.container {
  margin-right: auto;
  margin-left: auto;
  min-width: 450px;
}
.container-header {
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}
.col {
  flex: 1 0 0%;
  padding-right: 10px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.dialog {
  width: 90% !important;
}
@media screen and (min-width: 576px) {
  .container {
    max-width: 540px;
  }
  .row {
    flex-direction: column;
  }
  .dialog {
    width: 90% !important;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
  .row {
    flex-direction: column;
  }
  .dialog {
    width: 80% !important;
  }
}
@media screen and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
  .row {
    flex-direction: column;
  }
  .dialog {
    width: 80% !important;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
  .row {
    flex-direction: row;
  }
  .dialog {
    width: 30% !important;
  }
}
@media screen and (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
  .row {
    flex-direction: row;
  }
  .dialog {
    width: 30% !important;
  }
}
.timing {
  color: rgba(17, 17, 17, 0.4);
  font-weight: 500;
}
.order-card {
  background: #ffffff;
  box-shadow: 0px 6px 16px rgb(15 104 170 / 12%);
  border-radius: 4px;
  padding: 1rem;
}
.order-card-number {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin-right: 5px;
}
.order-card-info {
  text-align: left;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.order-card-status {
  width: 140px;
  height: 20px;
  background-color: rgba(124, 201, 77, 1);
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  color: white;
  border-radius: 4px;
  padding: 2px 10px 2px 10px;
  position: relative;
  top: -2px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.order-info-header {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}
.order-items-header {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin-bottom: 1.5rem;
}
.order-items-count {
  display: flex;
  font-weight: 700;
  margin-bottom: 1.5rem;
}
.order-items-count-circle {
  display: flex;
  align-items: center;
  margin: 4px;
}
.order-items-count-circle-img {
  background: black;
  border-radius: 50%;
  width: 4px;
  height: 4px;
}
.order-items-it {
  display: flex;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(17, 17, 17, 0.08);
}
.order-items-itog-separator {
  border-bottom: 1px dashed rgba(17, 17, 17, 0.16);
  flex-grow: 1;
}
.order-items-itog-it {
  display: flex;
}
.order-items-itog-deliv {
  display: flex;
}
.order-items-itog-itogo {
  display: flex;
  font-weight: 700;
}
.order-items-comment{
  margin-top: 1.5rem;
}
.form-field {
  text-align: left;
  margin-bottom: 1.5rem;
}
.form-field-key {
  color: rgba(17, 17, 17, 0.4);
  font-weight: 500;
}
.btnIsActive {
  font-weight: 600 !important;
}
.el-rate__icon {
  font-size: 28px !important;
}
.el-rate {
  height: 28px !important;
}
.courier-rate,
.courier-cmnt,
.question-cmnt {
  margin-bottom: 3rem;
}
.courier-rate-header,
.courier-cmnt-header,
.question-cmnt-header {
  color: black;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0.5rem;
}
.courier-rate-mark {
  margin-bottom: 0.5rem;
}
.courier-rate-comment,
.courier-cmnt-comment,
.question-cmnt-comment {
  font-size: 12px;
  margin-bottom: 0.5rem;
}
.courier-buttons,
.question-buttons {
  display: flex;
  flex-direction: column;
}

body {
  font-size: 12px;
  line-height: 16px;
  font-family: "Montserrat", sans-serif;
}
</style>
