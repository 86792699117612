import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'
Vue.use(VueRouter);
const routes = [
  
]
const router = new VueRouter({
  mode: 'history',
  routes // сокращённая запись для `routes: routes`
})

//ElementUI Library
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ru-RU'
Vue.use(ElementUI, { locale });

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
