export const dates = Object.freeze({
    name: 'Работа с датами',
    methods: {
        toStringDate(dat) {
            if (dat) {
                try {
                    let dd = new Date(dat);
                    return [
                        dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate(),
                        dd.getMonth() < 9 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1,
                        dd.getFullYear(),
                    ].join(".");
                } catch (e) {
                    console.log(e);
                    return "";
                }
            }
            return "";
        },
        today() {
            let dat = new Date();
            return new Date(dat.getFullYear(),dat.getMonth(),dat.getDate());
        },
        toStringDateTime(dat) {
            if (dat) {
                try {
                    let dd = new Date(dat);
                    let date = [
                        dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate(),
                        dd.getMonth() < 9 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1,
                        dd.getFullYear(),
                    ].join(".");

                    let time = [dd.getHours() < 10 ? "0" + dd.getHours() : dd.getHours(),
                    dd.getMinutes() < 10 ? "0" + dd.getMinutes() : dd.getMinutes(),
                    dd.getSeconds() < 10 ? "0" + dd.getSeconds() : dd.getSeconds()].join(
                        ":"
                    );
                    return date + " " + time;
                } catch (e) {
                    console.log(e);
                    return "";
                }
            }
            return "";
        }        
    }
});